import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"
import anime from "animejs"
import React from "react"
import { FiMail, FiTwitter } from "react-icons/fi"

import { FaFacebook, FaInstagram } from "react-icons/fa"

import { FaArrowRight } from "react-icons/fa"

import { slide as Menu } from "react-burger-menu"

function slideInfo() {
  anime({
    targets: ["#info__box", ".info__social"],
    translateX: "275px",
    duration: 1000,
  })
}

export default ({ location }) => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        allSanityInfo {
          edges {
            node {
              designer
              designerUrl
              photo
              photoUrl
              mail
              facebook
              twitter
              instagram
              details
              copyright
            }
          }
        }
      }
    `}
    render={data => (
      <Menu
        customBurgerIcon={
          <FaArrowRight
            color={location.pathname === "/" ? "white" : "#1a1a1a"}
          />
        }
      >
        <div className="menu">
          <Link to="/" activeStyle={{ color: "darkgreen" }}>
            home
          </Link>
          <Link to="/music/" activeStyle={{ color: "darkgreen" }}>
            music
          </Link>
          <Link to="/live/" activeStyle={{ color: "darkgreen" }}>
            live
          </Link>
          <Link to="/projects/" activeStyle={{ color: "darkgreen" }}>
            projects
          </Link>
          <Link to="/about/" activeStyle={{ color: "darkgreen" }}>
            about
          </Link>
          <div id="info__button" onClick={() => slideInfo()}>
            info
          </div>
          {data.allSanityInfo.edges.map(({ node: info }) => (
            <div key={info.toString()}>
              <div id="info__box">
                <p className="nopadding">CHA BLASCO:</p>
                <p className="cha__info">{info.details}</p>

                <p>
                  WEB & DESIGN:{" "}
                  <a
                    className="info__links"
                    href={info.designerUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {info.designer}
                  </a>
                </p>
                <p>
                  PHOTO:{" "}
                  <a
                    className="info__links"
                    href={info.photoUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {info.photo}
                  </a>
                </p>
                <div className="copyright">
                  <p>{info.copyright}</p>
                </div>
              </div>

              <div className="info__social">
                <div>
                  <a
                    href={"mailto:" + info.mail}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FiMail />
                  </a>
                </div>

                <div>
                  <a
                    href={info.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FiTwitter />
                  </a>
                </div>
                <div>
                  <a
                    href={info.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram />
                  </a>
                </div>
                <div>
                  <a
                    href={info.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebook />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Menu>
    )}
  />
)
